import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Type Brigade №20`}</h1>
    <p>{`During a lecture a few months back, designer Peter Cocking quipped “I find, if you speak about anything for long enough, it always comes back to type.”`}</p>
    <p>{`All the more reason to speak about it sooner.`}</p>
    <figure>
![](../images/blog-our-number-twenty-by-andrea-husky.svg)
  <figcaption>
  The Type Brigade №20 event numerals, designed by our Assistant Organiser & Resident Letterer [Andrea Husky](http://instagram.com/andreahusky).
  </figcaption>
    </figure>
    {
      /*more*/
    }
    <h2>{`Further reading…`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      